import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { formatCurrency, formatWithoutTimezone } from "../../utils/common";
import { format } from "date-fns";
import PreviewContainer from "./PreviewContainer";
import PreviewItem from "./PreviewItem";

const RemittanceSection = ({ transaction, coins, isInward }) => {
  const {
    reference_number,
    sender,
    send_amount,
    send_currency,
    receive_amount,
    receive_currency,
    created_date,
    created_user,
    deposit_type,
    purpose,
    type,
    receipt_mto,
    sender_commission,
    receiver_commission,
    stb_fee,
    customer_paid_amount,
  } = transaction;

  let remittanceContent;

  if (isInward) {
    remittanceContent = (
      <>
        <PreviewItem
          title={"Payment Order Number"}
          content={reference_number}
        />
        <PreviewItem
          title={"Remittance Amount"}
          content={formatCurrency(
            send_amount,
            send_currency.decimal_place,
            send_currency.symbol
          )}
        />
        <PreviewItem
          title={"Receiver Fee"}
          content={formatCurrency(
            receiver_commission,
            send_currency.decimal_place,
            send_currency.symbol
          )}
        />
        <PreviewItem
          title={"Total Amount"}
          content={formatCurrency(
            Number(send_amount) + Number(receiver_commission),
            send_currency.decimal_place,
            send_currency.symbol
          )}
        />
        <PreviewItem
          title={"Amount to Payout"}
          content={formatCurrency(
            receive_amount,
            receive_currency.decimal_place,
            receive_currency.symbol
          )}
        />
        <PreviewItem title={"Purpose of Remittance"} content={purpose?.name} />

        <PreviewItem title={"Sender MTO"} content={sender?.mto?.name} />
        <PreviewItem title={"Receiver MTO"} content={receipt_mto?.name} />
        <PreviewItem
          title={"Conversion Rate"}
          content={`1 ${send_currency.symbol} = ${Number(
            (receive_amount / send_amount).toFixed(
              receive_currency.decimal_place
            )
          )} ${receive_currency.symbol}`}
        />
        <PreviewItem title={"Type"} content={type} />
        <PreviewItem
          title={"Date"}
          content={formatWithoutTimezone(created_date, "dd MMM yyyy HH:mm a")}
        />
      </>
    );
  } else {
    remittanceContent = (
      <>
        <PreviewItem
          title={"Payment Order Number"}
          content={reference_number}
        />
        <PreviewItem
          title={"Remittance Amount"}
          content={formatCurrency(
            send_amount,
            send_currency.decimal_place,
            send_currency.symbol
          )}
        />
        <PreviewItem
          title={"Sender MTO Fee"}
          content={formatCurrency(
            sender_commission,
            send_currency.decimal_place,
            send_currency.symbol
          )}
        />
        <PreviewItem
          title={"Total Fee"}
          content={formatCurrency(
            Number(sender_commission) +
              Number(receiver_commission) +
              Number(stb_fee),
            send_currency.decimal_place,
            send_currency.symbol
          )}
        />
        <PreviewItem
          title={"Total Deposit Amount"}
          content={formatCurrency(
            customer_paid_amount,
            send_currency.decimal_place,
            send_currency.symbol
          )}
        />
        <PreviewItem
          title={"Amount to Payout"}
          content={formatCurrency(
            receive_amount,
            receive_currency.decimal_place,
            receive_currency.symbol
          )}
        />
        <PreviewItem title={"Deposit Type"} content={deposit_type} />
        <PreviewItem title={"Purpose of Remittance"} content={purpose?.name} />

        <PreviewItem title={"Sender MTO"} content={sender?.mto?.name} />
        <PreviewItem title={"Receiver MTO"} content={receipt_mto?.name} />
        <PreviewItem
          title={"Conversion Rate"}
          content={`1 ${send_currency.symbol} = ${Number(
            (receive_amount / send_amount).toFixed(
              receive_currency.decimal_place
            )
          )} ${receive_currency.symbol}`}
        />
        <PreviewItem title={"Type"} content={type} />
        <PreviewItem
          title={"Date"}
          content={formatWithoutTimezone(created_date, "dd MMM yyyy HH:mm a")}
        />
        <PreviewItem title={"Created By"} content={created_user} />
      </>
    );
  }
  return (
    <View style={{ marginBottom: "30px" }} wrap={false}>
      <PreviewContainer title={"Remittance Details"}>
        {remittanceContent}
      </PreviewContainer>
    </View>
  );
};

export default RemittanceSection;
